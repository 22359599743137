import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../app/layout"
import { Row, Col, Typography, Breadcrumb, Space } from "antd"
import Container from "../components/Container"
import { ChevronRight } from "../components/Icon"
import { useI18next } from "gatsby-plugin-react-i18next"

function toArray(data) {
  const rests = Object.keys(data).map(d => data[d])
  // console.log(rests)
  return rests
}

const PetaSitus = ({
  data: {
    allSiteMaps: { nodes: data = [] },
  },
}) => {
  const { t } = useI18next()
  const {
    about,
    investment,
    awardee,
    grantee,
    information,
    research,
    scholarship,
  } = data[0]

  return (
    <Layout>
      <Seo title="Peta Situs" />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Peta Situs</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      <Container>
        <Typography.Title style={{ margin: "50px 0px 70px 0px" }}>
          Peta Situs
        </Typography.Title>
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Typography.Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              TENTANG
            </Typography.Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(about).map((d, i) => (
                <Col xs={24} sm={6} key={i}>
                  <Space direction="vertical" style={{ marginBottom: 15 }}>
                    <Link to={d.url}>{d.title}</Link>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <hr style={{ margin: "40px 0px" }} />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Typography.Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              INVESTASI
            </Typography.Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(investment).map((d, i) => (
                <Col xs={24} sm={6} key={i}>
                  <Space direction="vertical" style={{ marginBottom: 15 }}>
                    <Link to={d.url}>{d.title}</Link>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <hr style={{ margin: "40px 0px" }} />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Typography.Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              BEASISWA
            </Typography.Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(scholarship).map((d, i) => {
                if (Array.isArray(d)) {
                  return d.map((dd, ii) => (
                    <Col xs={24} sm={6} key={ii + "cc"}>
                      <Space direction="vertical" style={{ marginBottom: 15 }}>
                        <Link to={dd.url}>{dd.title}</Link>
                      </Space>
                    </Col>
                  ))
                }
                return (
                  <Col xs={24} sm={6} key={i}>
                    <Space direction="vertical" style={{ marginBottom: 15 }}>
                      <Link to={d.url}>{d.title}</Link>
                    </Space>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
        <hr style={{ margin: "40px 0px" }} />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Typography.Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              AWARDEE
            </Typography.Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(awardee).map((d, i) => (
                <Col xs={24} sm={6} key={i}>
                  <Space direction="vertical" style={{ marginBottom: 15 }}>
                    <Link to={d.url}>{d.title}</Link>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <hr style={{ margin: "40px 0px" }} />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Typography.Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              RISET
            </Typography.Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(research).map((d, i) => {
                if (Array.isArray(d)) {
                  return d.map((dd, ii) => (
                    <Col xs={24} sm={6} key={ii + "cc"}>
                      <Space direction="vertical" style={{ marginBottom: 15 }}>
                        <Link to={dd.url}>{dd.title}</Link>
                      </Space>
                    </Col>
                  ))
                }
                return (
                  <Col xs={24} sm={6} key={i}>
                    <Space direction="vertical" style={{ marginBottom: 15 }}>
                      <Link to={d.url}>{d.title}</Link>
                    </Space>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
        <hr style={{ margin: "40px 0px" }} />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Typography.Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              GRANTEES
            </Typography.Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(grantee).map((d, i) => (
                <Col xs={24} sm={6} key={i}>
                  <Space direction="vertical" style={{ marginBottom: 15 }}>
                    <Link to={d.url}>{d.title}</Link>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <hr style={{ margin: "40px 0px" }} />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Typography.Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              INFORMASI
            </Typography.Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(information).map((d, i) => (
                <Col xs={24} sm={6} key={i}>
                  <Space direction="vertical" style={{ marginBottom: 15 }}>
                    <Link to={d.url}>{d.title}</Link>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <hr style={{ margin: "40px 0px" }} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query petaSitusQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSiteMaps(filter: { lang: { eq: $language } }) {
      nodes {
        scholarship {
          affirmation {
            url
            title
          }
          collaboration {
            url
            title
          }
          general {
            url
            title
          }
          public_policy {
            url
            title
          }
          registration_scheduling {
            url
            title
          }
          targeted {
            url
            title
          }
        }
        research {
          public_policy {
            title
            url
          }
          registration_scheduling {
            title
            url
          }
          rispro_collaboration {
            title
            url
          }
          rispro_competition {
            url
            title
          }
          rispro_invitation {
            title
            url
          }
          rispro_mandatory {
            title
            url
          }
        }
        investment {
          donation {
            title
            url
          }
          instrument_portfolio {
            title
            url
          }
          scheme_policy {
            title
            url
          }
        }
        information {
          career {
            title
            url
          }
          contact_us {
            title
            url
          }
          faq {
            title
            url
          }
          financial_report {
            title
            url
          }
          infographics {
            title
            url
          }
          live {
            title
            url
          }
          news {
            title
            url
          }
          performance_report {
            title
            url
          }
          yearly_report {
            title
            url
          }
          other_report {
            title
            url
          }
          public_information {
            title
            url
          }
        }
        grantee {
          inspiration_story {
            title
            url
          }
          lisf_of_grantee {
            title
            url
          }
          rispro_tech_brief {
            title
            url
          }
        }
        awardee {
          contribution {
            title
            url
          }
          departure_preparation_generation {
            title
            url
          }
          departure_preparation_policy {
            title
            url
          }
          grantee_featured_generation {
            title
            url
          }
          grantee_guide {
            title
            url
          }
          grantee_mata_garuda {
            title
            url
          }
          language_enrichment {
            title
            url
          }
          serba_serbi {
            title
            url
          }
        }
        about {
          award {
            title
            url
          }
          management {
            title
            url
          }
          organization_structure {
            title
            url
          }
          selayang_pandang {
            title
            url
          }
          testimony {
            title
            url
          }
          vision_mission {
            title
            url
          }
        }
      }
    }
  }
`

export default PetaSitus
